<template>
	<div class="box">
		<div class="flex" style="justify-content: space-between">
			<Title title="我的资源列表" />
			<button class="add_but" @click="go_add">添加资源</button>
		</div>
		<div class="ct_box">
			<ul class="nav flex">
				<li @click="nav_tab(index)" :class="pubNav == index ? 'active' : ''" v-for="(item, index) in nav_list"
					:key="index">{{ item.title }}
					<el-badge :value="item.order_num" class="item" v-if="item.order_num" :max="99">
					</el-badge>
				</li>
			</ul>
			<!-- <orderNav /> -->
			<router-view></router-view>
			<el-dialog title="被拒绝原因" :append-to-body="true" :visible.sync="Link" :show-close="false">
				<p>{{ reasonItem.reason }}</p>
			</el-dialog>
			<el-dialog title="编辑媒体" :append-to-body="true" :visible.sync="updateShow" :show-close="false">
			</el-dialog>
		</div>
	</div>
</template>

<script>
import Title from '@/components/UserInfoTitle'
// import orderNav from '@/components/order_nav'
import { mapState } from 'vuex'
export default {
	components: {
		Title,
		// orderNav
	},
	computed: {
		...mapState(['pubNav'])
	},
	mounted() {
		// this.$store.state.active = 1
		this.getMediaNav()

	},
	data() {
		return {
			Link: false,
			reasonText: '', //申请原因
			reasonItem: {},
			updateShow: false, //编辑窗口
			updateItem: {},
			nav_list: [],
		}
	},
	methods: {
		go_add() {
			this.$router.push({
				path: '/user/add_resource/News'
			})
		},
		getMediaNav() {
			this.curlGet('/api/medium_category/list').then(res => {
				if (res.data.code) {
					this.nav_list = res.data.data
					let url_arr = ['/user/pub_media/pubNews', '/user/pub_media/pubWeMedia', '/user/pub_media/pubPaperMedia', '/user/pub_media/pubOfficialAccountsMedia', '/user/pub_media/pubWbMedia', '/user/pub_media/pubRedbookMedia', '/user/pub_media/pubShortVideoMedia']
					this.nav_list.forEach((item, index) => {
						this.nav_list[index].path = url_arr[index]
					})
					// this.$store.state.UserMediaNav = res.data.data
					// this.$store.state.UserTypeItem = res.data.data[0]
				}
			})
		},
		nav_tab(index) {
			this.$store.state.pubNav = index
			console.log(this.pubNav);
			this.$router.push({
				path: this.nav_list[index].path
			})

		},

	},
}
</script>

<style lang="scss" scoped>
@import '@/scss/MediaOrder';
@import '@/scss/order_nav';

::v-deep .el-input__inner {
	height: 38px;
}
</style>